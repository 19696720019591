<template>
  <b-modal
    id="suspend-sender-id"
    centered
    title="Suspend/Reject Sender ID"
    hide-footer
    ref="suspend-sender-id-ref"
  >
    <b-card-text> Are you sure you want to suspend/reject this Sender ID ? </b-card-text>

    <hr />
    
    <div class="d-flex justify-content-end align-items-center">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="secondary"
        @click="hideModal"
      >
        Cancel
      </b-button>

      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="danger"
        class="ml-2"
        :disabled="isLoading"
        @click="suspendSenderId"
      >
        <b-spinner small v-if="isLoading" />
        <span v-if="isLoading">&nbsp;</span>
        Suspend
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import { BModal, BCardText, BSpinner, BButton } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  directives: {
    Ripple,
  },
  components: {
    BModal,
    BCardText,
    BSpinner,
    BButton,
  },
  props: ["id"],
  emits: ["sender-refresh"],
  data(){
    return {
      isLoading: false,
    };
  },
  methods:{
    suspendSenderId(){
      this.isLoading = true;
      this.$http
        .get("/sender-id/"+ this.id + "/status/rejected")
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: response.data.message,
              icon: "CheckIcon",
              variant: "success",
            },
          });
          this.$emit('sender-refresh');
          this.isLoading = false;
          this.hideModal();
        })
        .catch((error) => {
          for (let err of error.response.data.error) {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: err,
                icon: "AlertCircleIcon",
                variant: "danger",
              },
            });
          }
          this.isLoading = false;
        });
    },
    hideModal() {
      this.$refs['suspend-sender-id-ref'].hide();
    }
  }
};
</script>
